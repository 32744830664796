<div *ngIf="!auctionSelected" class="container-fluid pt-3">
  <div>
    <div class="card shadow">
      <div class="card-header py-3">
        <p class="text-primary m-0 fw-bold">Select Auction</p>
      </div>
      <div class="card-body">
        <div class="table-responsive table mt-2" id="dataTable" role="grid">
          <table class="table my-0" id="dataTable">
            <thead>
              <tr>
                <th>Auction Id</th>
                <th>City</th>
                <th>Ends on</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let auction of auctions" (click)="reloadData(auction.id)" style="cursor: pointer;">
                <td>{{auction.id}}</td>
                <td>{{auction.city}}, {{auction.state_abbreviation}}</td>
                <td>{{auction.end_time | date: 'medium'}}</td>
                <td>{{auction.title}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="auctionSelected" class="container-fluid pt-3">
  <div class="row">
    <div class="col">
      <div class="form-check">
        <input type="checkbox" (change)="_showZeroBidsOnly=!_showZeroBidsOnly; applyFilters()" [checked]="_showZeroBidsOnly" class="form-check-input"/>
        <label class="form-check-label">Show no bids only</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" (change)="_showNewOnly=!_showNewOnly;applyFilters()" [checked]="_showNewOnly" />
        <label class="form-check-label">Show new only</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" (change)="_showDamaged=!_showDamaged;applyFilters()" [checked]="_showDamaged" />
        <label class="form-check-label">Show Damaged</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" (change)="_showClosed=!_showClosed;applyFilters()" [checked]="_showClosed" />
        <label class="form-check-label">Show Closed</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" (change)="_showCountdown=!_showCountdown;applyFilters()" [checked]="_showCountdown" />
        <label class="form-check-label">Show Countdowns</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" (change)="_showImages=!_showImages;applyFilters()" [checked]="_showImages" />
        <label class="form-check-label">Show Thumbnails</label>
      </div>
    </div>
    <div class="col">
      <div class="input-group">
        <input class="form-control" type="text" #searchbox (input)="applySearch($event); log($event);" placeholder="Search"> 
        <button class="btn btn-primary btn-sm" (click)="searchbox.value=''; clearSearch()">Clear</button>
      </div>
    </div>
    <div class="col text-center">
      <button (click)="reloadData(auctionId)" class="btn btn-primary btn-sm mx-3">Reload Items</button>
      <button (click)="selectAuction()" class="btn btn-primary btn-sm">Select Auction</button>
      <br />
      Total Items: {{viewItems.length}}
    </div>
  </div>

  <div class="container-fluid pt-3">
    <div class="card shadow">
      <div class="card-header py-3">
        <p class="text-primary m-0 fw-bold">Auction Items</p>
      </div>
      <div class="card-body">
        <div class="table-responsive table mt-2" id="dataTable" role="grid">
          <table *ngIf="!loading else showLoading" class="table my-0" id="dataTable">
            <thead>
              <tr>
                <th scope="col" style="cursor: pointer;text-align: right; width: 105px;">
                  <div (click)="sortBy('bid')">Current bid
                    <div *ngIf="currentSort=='bid'">
                      <svg *ngIf="!reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                      </svg>
                      <svg *ngIf="reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th scope="col" style="cursor: pointer;text-align: right; width: 105px;">
                  <div (click)="sortBy('count')">Bid Count
                    <div *ngIf="currentSort=='count'">
                      <svg *ngIf="!reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                      </svg>
                      <svg *ngIf="reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th scope="col" style="cursor: pointer;text-align: right;">
                  <div (click)="sortBy('msrp')">MSRP
                    <div *ngIf="currentSort=='msrp'">
                      <svg *ngIf="!reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                      </svg>
                      <svg *ngIf="reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th scope="col" style="text-align: center;">
                  <div>New</div>
                </th>
                <th scope="col" style="text-align: center;">
                  <div>Un-damaged</div>
                </th>
                <th scope="col" style="cursor: pointer;text-align: right;">
                  <div (click)="sortBy('difference')">Diff
                    <div *ngIf="currentSort=='difference'">
                      <svg *ngIf="!reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                      </svg>
                      <svg *ngIf="reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th scope="col" style="cursor: pointer;text-align: right;">
                  <div (click)="sortBy('differencep')">% Diff
                    <div *ngIf="currentSort=='differencep'">
                      <svg *ngIf="!reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                      </svg>
                      <svg *ngIf="reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th scope="col" style="cursor: pointer; width: 200px;">
                  <div (click)="sortBy('date')">Ends {{_showCountdown? 'in':'at'}}
                    <div *ngIf="currentSort=='date'">
                      <svg *ngIf="!reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                      </svg>
                      <svg *ngIf="reverseSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div>Description</div>
                </th>
                <th scope="col">
                  <div></div>
                </th>
                <th scope="col">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of viewItems">
                <td style="text-align: right;">${{item.current_bid.toFixed(2)}}</td>
                <td style="text-align: right;">{{item.bid_count}}</td>
                <td style="text-align: right;">${{item.msrp.toFixed(2)}}</td>
                <td style="text-align: center;">
                  <svg *ngIf="item.new" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-lg"
                    viewBox="0 0 16 16">
                    <path
                      d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
                  </svg>
                  <svg *ngIf="!item.new" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg"
                    viewBox="0 0 16 16">
                    <path
                      d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                  </svg>
                </td>
                <td style="text-align: center;">
                  <svg *ngIf="!item.damaged" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-lg"
                    viewBox="0 0 16 16">
                    <path
                      d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
                  </svg>
                  <svg *ngIf="item.damaged" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg"
                    viewBox="0 0 16 16">
                    <path
                      d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                  </svg>
                </td>
                <td style="text-align: right;">${{item.difference.toFixed(2)}}</td>
                <td style="text-align: right;">{{item.differencePercentage.toFixed(2)}}%</td>
                <td style="width: fit-content;">
                  <div *ngIf="!_showCountdown">{{(item.ends) | date:'h:mm:ss'}}</div>
                  <countdown *ngIf="_showCountdown" [config]="{leftTime: item.ends_counter}"></countdown>
                </td>
                <td style="width:600px;word-wrap: break-word; text-overflow: ellipsis;overflow:hidden;" [title]="item.description">{{item.title}}</td>
                <td *ngIf="!_showImages" (click)="openImages(item)" style="cursor: pointer; text-align: center;">View Images</td>
                <td *ngIf="_showImages"><img [src]="item.images[0].thumb_url" style="max-height: 250px; cursor:pointer;" (click)="openImages(item)"></td>
                <td style="text-align: center;"><a href="https://www.nellisauction.com/auction/{{auctionId}}/item/{{item.id}}/" target="_blank" >Go to Auction</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <ng-template #showLoading><div class="center py-3">Loading items...</div></ng-template>

