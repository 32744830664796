import { Component, OnInit } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentPage = 1;
  pageItems = 1000;
  auctionId = '';
  auctionSelected = false;
  auctions = [];
  totalItems = 0;
  headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': `*/*`
  };
  rawItems = [];
  items = [];
  viewItems = [];
  _showDamaged = true;
  _showNewOnly = false;
  _showZeroBidsOnly = false;
  _showClosed = false;
  _showCountdown = false;
  _showImags = false;
  currentSort = '';
  reverseSort = false;
  keyword = '';
  loading = true;
  _album = [];

  constructor(
    private http: HttpClient, 
    private _lightbox: Lightbox
  ) {}
  ngOnInit(): void {
    this.getAuctions();
  }

  loadData() {
    this.loading = true;
    this.auctionSelected = true;
    this.http.post('https://www.nellisauction.com/api/getitems', this.getBody(), { headers: this.headers }).subscribe((data: any) => {
      console.log(data);
      this.totalItems = data.total;
      this.rawItems = this.rawItems.concat(data.items);
      if (this.rawItems.length < data.total) {
        this.currentPage = this.currentPage + 1;
        this.http.post('https://www.nellisauction.com/api/getitems', this.getBody(), { headers: this.headers }).subscribe((data2: any) => {
          this.rawItems = this.rawItems.concat(data2.items);
          if (this.rawItems.length < data.total) {
            this.currentPage = this.currentPage + 1;
            this.http.post('https://www.nellisauction.com/api/getitems', this.getBody(), { headers: this.headers }).subscribe((data3: any) => {
              this.rawItems = this.rawItems.concat(data3.items);
              if (this.rawItems.length < data.total) {
                this.currentPage = this.currentPage + 1;
                this.http.post('https://www.nellisauction.com/api/getitems', this.getBody(), { headers: this.headers }).subscribe((data4: any) => {
                  this.rawItems = this.rawItems.concat(data4.items);
                  this.parseData();
                });
              }
              else this.parseData();
            });
          }
          else this.parseData();
        });
      }
      else this.parseData();
    });
  }

  reloadData(auction:string){
    this.auctionSelected = false;
    this.loading = true;
    this.currentPage = 1;
    this.auctionId = auction;
    this.rawItems = [];
    this.loadData();
  }

  getBody() {
    return `auction_id=`+this.auctionId+`&filters%5Bnewsortlist%5D=current_bid+desc&filters%5Bpage%5D=` + this.currentPage + `&filters%5Bperpage%5D=`+this.pageItems+`&item_type=itemlist&lotnum=0&close_groups=&show_closed=closed`;
  }

  parseData(){
    let now = Math.floor(new Date().getTime() / 1000);
    this.items = this.rawItems.map(item => {
      item.msrp = item.extra_info.slice(item.extra_info.indexOf('$') + 1);
      item.msrp = parseFloat(item.msrp.substr(0, item.msrp.indexOf('<')));
      item.new = item.extra_info.indexOf('NEW') > 0;
      item.ends_counter = (item.end_time - item.time_offset) - now;
      item.ends = new Date((item.end_time - item.time_offset) * 1000);
      item.damaged = item.extra_info.indexOf('DAMAGE') > 0;
      item.current_bid = parseFloat(item.current_bid);
      item.difference = item.msrp - item.current_bid;
      item.differencePercentage = item.difference / item.msrp * 100;
      item.description = item.description.replace(/<[^<>]+>/g, '');
      return item;
    });
    this.items = this.items.filter(item => !Object.is(NaN, item.msrp));
    this.viewItems = [...this.items];
    this.applyFilters();
    this.loading = false;
  }

  applyFilters(){
    this.loading = true;
    this.viewItems = this.items.filter(item => this._showDamaged || !item.damaged );
    this.viewItems = this.viewItems.filter(item => !this._showNewOnly || item.new );
    this.viewItems = this.viewItems.filter(item => !this._showZeroBidsOnly || item.current_bid == 0 );
    this.viewItems = this.viewItems.filter(item => this._showClosed || item.ends_counter > 0);
    this.sortBy(this.currentSort);
    let now = Math.floor(new Date().getTime() / 1000);
    this.viewItems.forEach(x => {x.ends_counter = (x.end_time - x.time_offset) - now;})
    this.loading = false;
  }

  applySearch(event: any) {
    var keyword = event?.target?.value;
    if (keyword == '' || keyword == null){
      this.loading = true;
      this.applyFilters();
      this.loading = false;
    }
    else{
      if (event.inputType == 'deleteContentBackward') {
        this.loading = true;
        this.applyFilters();
        this.loading = false;
      }
      this.viewItems = this.viewItems.filter(item => item.title.toLowerCase().indexOf(keyword.toLowerCase()) >= 0 );
    }
  }

  clearSearch(){
    this.applySearch('');
  }

  sortByMsrp() {
    this.viewItems = this.viewItems.sort((a, b) => { return a.msrp - b.msrp });
  }

  sortByDifference() {
    this.viewItems = this.viewItems.sort((a, b) => { return a.differencePercentage - b.differencePercentage; });
  }

  sortBy(sort:string) {
    switch (sort) {
      case 'bid':
        if (this.currentSort = sort) this.reverseSort = !this.reverseSort;
        this.viewItems = this.viewItems.sort((a, b) => { return (this.reverseSort) ? a.current_bid - b.current_bid : b.current_bid - a.current_bid });
        break;
        
      case 'count':
        if (this.currentSort = sort) this.reverseSort = !this.reverseSort;
        this.viewItems = this.viewItems.sort((a, b) => { return (this.reverseSort) ? a.bid_count - b.bid_count : b.bid_count - a.bid_count });
        break;

      case 'msrp':
        if (this.currentSort = sort) this.reverseSort = !this.reverseSort;
        this.viewItems = this.viewItems.sort((a, b) => { return (this.reverseSort)?a.msrp - b.msrp : b.msrp - a.msrp});
        break;

      case 'difference':
        if (this.currentSort = sort) this.reverseSort = !this.reverseSort;
        this.viewItems = this.viewItems.sort((a, b) => { return (this.reverseSort) ? a.difference - b.difference : b.difference - a.difference });
        break;

      case 'differencep':
        if (this.currentSort = sort) this.reverseSort = !this.reverseSort;
        this.viewItems = this.viewItems.sort((a, b) => { return (this.reverseSort) ? (a.differencePercentage + a.msrp / 100000) - (b.differencePercentage + b.msrp / 100000) : (b.differencePercentage + b.msrp / 100000) - (a.differencePercentage + a.msrp / 100000) });
        break;

      case 'date':
        if (this.currentSort = sort) this.reverseSort = !this.reverseSort;
        this.viewItems = this.viewItems.sort((a, b) => { return (this.reverseSort) ? a.ends_counter - b.ends_counter : b.ends_counter - a.ends_counter });
        break;
    }
  }

  login(){

    this.http.post('https://www.nellisauction.com/api/getitems', this.getBody(), { headers: this.headers }).subscribe((data: any) => {
      console.log(data);
    });
  }

  log(item:any){
    console.log(item);
  }

  getAuctions() {
    this.auctions=[];
    this.http.post('https://www.nellisauction.com/api/auctionlistnel', '', { headers: this.headers }).subscribe((data: any) => {
      console.log(data);
      this.auctions = data.data.map(item => {
        item.end_time = new Date(item.ends * 1000);
        return item;
      });
    });
  }

  openImages(item:any){
    this._album = item.images.map(image => {
      return {'src': image.image_url}
    });
    this._lightbox.open(this._album);
  }

  selectAuction(){
    this.auctionSelected = false;
    this.getAuctions();
  }

  getTime = (ends:number)=> { 
    return Math.floor(((new Date().getTime())/1000) - (ends));
  }
}
